<template>

  <countTo ref='counter' :startVal='startValue' :endVal='endValue' :duration='period' :autoplay="autoplay"></countTo>

</template>
<script>

  import countTo from 'vue-count-to';
  export default {
    name:'Counter',
    components: { countTo },
    props: {
      target:{
        required:true,
        type:String
      },
      startValue:{
        type:Number,
        default:0,
      },
      endValue:{
        required:true,
        type:Number
      },
      period:{
        required:false,
        type:Number,
        default:2500
      }
    }, 
    data() {
      return {
        counter:null,
        targetActive: false,
        startVal: this.startVal,
        endVal: this.endVal,
        autoplay:false
      };
    },
    mounted() {
      let da = this;
      $(window).scroll(function() {    
        if(da.isScrolledIntoView($(da.target)))
        {
          da.targetActive = true;
          

          // console.log("hi");
        }    
      });
    },
    methods: {
      isScrolledIntoView(elem){
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
        return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
      },
      start1() {
        this.$refs.counter.start();
      },
    },
    watch : {
     targetActive:function(val) {
      // this.counter = this.startVal;
      //  let da = this;
      // setInterval(function() {
      //   if(da.counter < da.endVal ){
      //     da.counter += 1;
      //   }
      // }, 0.0001); 
      if( this.targetActive ){
        this.start1();
      }
    },
  }

};
</script>
