<template>
  <div class="uploader" :class="classes">
    <!-- <label class="uploaded-label" v-show="!previewSrc" :for="name">{{  label || name }}</label> -->
    <img v-show="previewSrc" :src="previewSrc"  class="uploaded-img">
    <input :accept="accept" ref="fileInput" v-show="false" type="file" :id="name" :name="name" @change="inputChanged">
    <button type="button" v-show="! previewSrc" class="button add-btn" @click="selectFile"><i class="fa fa-upload"></i>{{label}}</button>
    <button type="button" v-show="previewSrc" class="button delete-btn" @click="removeFile"><i class="fa fa-trash"></i> مسح</button>
</div>
</template>

<script>
  export default {
    name: 'file-input',
    props: ['classes', 'name', 'file', 'label' , 'accept'],
    data: () => ({
      addedFile: null,
      removed: false
  }),
    computed: {
      previewSrc() {
        if (! this.addedFile) {
          return this.removed ? false : this.file;
      }
      if(this.addedFile.type.includes("pdf")){
            return "/front/img/illustrations/pdf.png"
        }else{

          return URL.createObjectURL(this.addedFile);
      }
  }
},
methods: {
  inputChanged(event) {
    this.$emit('input', event.target.files);
    this.addedFile = event.target.files[0];
    this.removed = false;
},
selectFile() {
    this.$refs.fileInput.click();
},
removeFile() {
    this.$refs.fileInput.value = '';
    this.addedFile = null;
    this.removed = true;
}
}
};
</script>
<style >
.uploader {
    width: 100%;
    height: 150px;
    text-align: center;
}
img.uploaded-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    object-fit: contain;
}

.button.add-btn {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 18px;
}
.button.delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000054;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

</style>
