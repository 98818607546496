<template>
    <div class="form-block">
        <form ref="form" :action="action" :method="method" @submit.prevent="onSubmit">
            <slot>
            </slot>
        </form>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'slot-form',

    props: {
        method: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        }
    },
    methods: {
        onSubmit(){
            const data = new FormData(this.$refs.form);
            const method = this.method;
            const action = this.action;
            axios.post(action, data)
            .then( response => {
                this.showAlert('success' ,  response.data.message);
                this.clearInputs();
                if (response.data.route) {
                    setTimeout(function(){
                      window.location.href = `${response.data.route}`;
                  }, 2000);
                    
                }
            })
            .catch(error => {
                console.clear();
                this.showAlert('error' , null , null ,  `<ul class="AlertList">`+Object.values(error.response.data.errors).map(error => `<li class="mb5 is-size-6">${error[0]}</li>`).join(' ')+`</ul>`);
            });
        },
        clearInputs(){
            let inputs = this.$refs.form;
            $.each(inputs, function(key, value) {
                if(value.tagName  == 'INPUT' || value.tagName  == 'TEXTAREA'){
                    value.value ="";
                }
            });
        },
        showAlert(type , title , text , html){
            this.$swal({
              type: type,
              title: title,
              text: text,
              html: html,
              showConfirmButton: false,
              timer: 2000,
          });
        }
    }
};
</script>
<style>
.form-block{
    position: relative;
}
</style>
