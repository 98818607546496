<template>
	<div>
		<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="6">
			<div class="columns is-multiline is-centered">
				<!-- <div class="column is-3" v-for="product in items">
					<div class="service-block wow fadeInUp card px-4 py-4">
						<i class="icon-24-hours-medical-assistance icon-4rem has-text-primary mb-4 is-block"></i>
						<p class="has-text-black-bis mb-3"><small>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.</small></p>
						<a href="#" class="has-text-warning has-text-weight-bold">المزيد  <i class="fas fa-arrow-left vertical-sub"></i></a>
					</div>
				</div> -->
				<div class="column is-3">
					<div class="service-block wow fadeInUp card px-4 py-4">
						<i class="icon-24-hours-medical-assistance icon-4rem has-text-primary mb-4 is-block"></i>
						<p class="has-text-black-bis mb-3"><small>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.</small></p>
						<a href="#" class="has-text-warning has-text-weight-bold">المزيد  <i class="fas fa-arrow-left vertical-sub"></i></a>
					</div>
				</div>
				<div class="column is-3">
					<div class="service-block wow fadeInUp card px-4 py-4">
						<i class="icon-pharmacy-delivery-of-herbs-and-mortar-for-preparation-of-natural-healthy-medicines icon-4rem has-text-primary mb-4 is-block"></i>
						<p class="has-text-black-bis mb-3"><small>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.</small></p>
						<a href="#" class="has-text-warning has-text-weight-bold">المزيد  <i class="fas fa-arrow-left vertical-sub"></i></a>
					</div>
				</div>
				<div class="column is-3">
					<div class="service-block wow fadeInUp card px-4 py-4">
						<i class="icon-ambulance-assistance-24-hours icon-4rem has-text-primary mb-4 is-block"></i>
						<p class="has-text-black-bis mb-3"><small>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.</small></p>
						<a href="#" class="has-text-warning has-text-weight-bold">المزيد  <i class="fas fa-arrow-left vertical-sub"></i></a>
					</div>
				</div>
				<div class="column is-3">
					<div class="service-block wow fadeInUp card px-4 py-4">
						<i class="icon-ambulance-assistance-24-hours icon-4rem has-text-primary mb-4 is-block"></i>
						<p class="has-text-black-bis mb-3"><small>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.</small></p>
						<a href="#" class="has-text-warning has-text-weight-bold">المزيد  <i class="fas fa-arrow-left vertical-sub"></i></a>
					</div>
				</div>
			</div>

			<div class="has-text-centered full-width mt30" v-show="loadMore">
				<div class="iloader position-relative is-inline-block w-100">
					<img src="/front/img/illustrations/loader.svg" alt="" class="height-100" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import infiniteScroll from 'vue-infinite-scroll';
export default {
	name: 'Team',
	directives: {
		infiniteScroll
	},
	props: {
		data: {
			type: Array,
			default: () => []
		},
		url: String,
	},
	computed: {
		busy() {
			return this.fetching
		}
	},
	data: () => ({
		items: [],
		currentPage: 0,
		fetching: false,
		nextUrl : String,
		loadMore : true,
	}),
	methods: {
		loadNextPage() {
			this.currentPage++;
			this.fetching = true;
			if(this.nextUrl != null){
				axios.get(this.nextUrl).then(({ data }) => {
					this.items.push(...data.data);
					this.nextUrl = data.links.next;
					this.fetching = false;
				});
			}
			else{
				this.loadMore = false;
			}   
		}
	},
	created() {
		this.nextUrl = this.url
		this.items = [];
		this.loadNextPage();
	}
};
</script>